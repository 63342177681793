import { useCallback, useState } from 'react'
import { DocumentNode, gql, TypedDocumentNode, useApolloClient } from '@apollo/client'
import { useOrderListContext } from '../stores/useOrderListContext'
import { useEntities } from './useEntities'
import {
  GetProductCountForConstructionDocumentationQueryVariables,
  OrderShippingType,
  OrdersInput,
  OrderV2,
  SearchOrdersV2Query,
  SearchOrdersV2QueryVariables,
  SearchOrdersStartPageQuery,
  SearchOrdersStartPageQueryVariables,
  HasOrdersQuery,
  HasOrdersQueryVariables,
} from '@obeta/schema'
import { StoreV2 } from '@obeta/models/lib/models'
import { trackCustom } from '@obeta/utils/lib/tracking'
import dayjs from 'dayjs'
import { HAS_ORDERS_QUERY } from '../queries/orders'

export const SEARCH_QUERY = gql`
  query searchOrdersV2($input: OrdersInput!) {
    searchOrdersV2(input: $input) {
      success
      errorMessage
      errorCode
      totalOrderCount
      orders {
        id
        offerId
        name
        userId
        type
        itemStates {
          type
          count
        }
        collector
        purchaser
        clerk
        projectName
        customerReference
        commissionText
        shippingType
        shippingAddress
        storeId
        shippingDate
        orderTotalSum
        supplementaryCosts
        itemCount
        items {
          id
          product {
            imageData {
              images {
                large
              }
            }
            images {
              url
              width
            }
            title
            oxomiId
            sapId
            supplierId
            type
          }
          supplierImageData {
            large
          }
        }
        createdAt
        lastUpdated
      }
      facets {
        purchaser {
          count
          isAddition
          name
          search
        }
        itemStates {
          count
          isAddition
          name
          search
        }
        orderType {
          count
          isAddition
          name
          search
        }
        projectName {
          count
          isAddition
          name
          search
        }
        shippingType {
          count
          isAddition
          name
          search
        }
        supplierIds {
          count
          isAddition
          name
          search
        }
      }
    }
  }
`

export const PRODUCT_COUNT_QUERY = gql`
  query getProductCountForConstructionDocumentation($input: OrdersInput!) {
    getProductCountForConstructionDocumentation(input: $input) {
      success
      errorMessage
      errorCode
      sapIdsCount
    }
  }
`
// Constants
export const SEARCH_QUERY_START_PAGE = gql`
  query searchOrdersStartPage($input: OrdersInput!) {
    searchOrders(input: $input) {
      success
      errorMessage
      errorCode
      totalOrderCount
      orders {
        id
        name
        type
        itemStates {
          type
          count
        }
        commissionText
        shippingType
        shippingAddress
        storeId
        shippingDate
        itemCount
        items {
          id
          product {
            imageData {
              images {
                large
              }
            }
            images {
              url
              width
            }
            title
            oxomiId
            sapId
            supplierId
            type
          }
          supplierImageData {
            large
          }
        }
        createdAt
        lastUpdated
      }
    }
  }
`

export const ORDERS_INPUT_FOR_START_PAGE: OrdersInput = {
  filter: [],
  filterV2: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dateFrom: dayjs('1970-01-01').format('YYYY-MM-DD')!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dateTo: dayjs().format('YYYY-MM-DD')!,
    itemStates: [],
    orderType: ['Order'],
    purchaser: [],
    projectName: [],
    shippingType: [],
  },
  includeArchived: true,
  limit: '4',
  offset: '0',
  orderBy: 'createdAt',
  orderDir: 'DESC',
  orderItemLimit: '7',
  searchTerm: '',
}
export const useOrderSearch = () => {
  const client = useApolloClient()
  const stores: StoreV2[] = useEntities('storesv2')
  const { orders, setFacets, setIsLoading, setIsLoadingPage, setOrders, setTotalOrderCount } =
    useOrderListContext()
  const [hasOrdersInArrears, setHasOrdersInArrears] = useState<boolean>(false)
  const [dateOfOldestOrderInArrears, setDateOfOldestOrderInArrears] = useState<string>(
    dayjs('1970-01-01').format('YYYY-MM-DD')
  )

  /**
   * Get shipping address by shipping type.
   * @param shippingAddress string
   * @param shippingType OrderShippingType
   * @param storeId OrderStoreId
   * @returns Updated shipping address
   */
  const getShippingAddress = (
    shippingAddress: string,
    shippingType: OrderShippingType,
    storeId: string
  ): string => {
    let updatedShippingAddress = shippingAddress
    if (shippingType === 'DefaultPickup' || shippingType === 'ExpressPickup') {
      const pickUpStore = stores.find((store) => store.id === storeId)
      if (pickUpStore) {
        const address = pickUpStore?.address
        updatedShippingAddress = `${address?.name1}, ${address?.street} ${address?.houseNumber}, ${address?.zipCode} ${address?.city}`
      }
    }
    return updatedShippingAddress
  }

  const queryHasOrders = async (): Promise<boolean> => {
    try {
      const response = await client.query<HasOrdersQuery, HasOrdersQueryVariables>({
        query: HAS_ORDERS_QUERY,
      })

      const data = response.data.hasOrders
      if (data?.totalOrderCount > 0) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  /**
   * @param input OrdersInput
   * @param query
   * @returns SearchOrdersQuery
   */
  const queryOrders = async <T,>(
    input: OrdersInput,
    query: DocumentNode | TypedDocumentNode,
    signal?: AbortSignal
  ): Promise<T | undefined> => {
    try {
      setIsLoading(true)

      const response = await client.query<
        T,
        | SearchOrdersV2QueryVariables
        | SearchOrdersStartPageQueryVariables
        | GetProductCountForConstructionDocumentationQueryVariables
      >({
        query,
        variables: {
          input,
        },

        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: { signal },
        },
      })

      setIsLoading(false)

      return response.data
    } catch (error) {
      return undefined
    }
  }

  /**
   * Handler to search orders by input variables.
   * @param input OrdersInput
   */
  const searchOrders = useCallback(
    async (input: OrdersInput) => {
      const data = (await queryOrders<SearchOrdersV2Query>(input, SEARCH_QUERY))?.searchOrdersV2

      if (data?.success) {
        trackCustom('search-orders', {
          request: input,
          response: data.orders,
        })

        setTotalOrderCount(data.totalOrderCount)

        setFacets(data.facets)
        if (input.offset === '0') {
          // Replace orders on filter change
          setOrders([...(data.orders as OrderV2[])])
        } else {
          // Add orders on scroll
          setOrders([...orders, ...data.orders])
        }
      } else {
        trackCustom('search-orders-error', data)
      }
      setIsLoadingPage(false)
    },
    // eslint-disable-next-line
    [orders]
  )

  const checkHasOrdersInArrears = async () => {
    const data = await queryOrders<SearchOrdersStartPageQuery>(
      {
        ...ORDERS_INPUT_FOR_START_PAGE,
        limit: '1',
        orderItemLimit: '1',
        orderDir: 'ASC',
        filterV2: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...ORDERS_INPUT_FOR_START_PAGE.filterV2!,
          itemStates: ['InArrears'],
        },
      },
      SEARCH_QUERY_START_PAGE
    )
    if (data && data.searchOrders.orders?.length > 0) {
      setHasOrdersInArrears(true)
      setDateOfOldestOrderInArrears(
        dayjs(data.searchOrders.orders[0].createdAt).format('YYYY-MM-DD')
      )
    }
  }

  return {
    checkHasOrdersInArrears,
    dateOfOldestOrderInArrears,
    getShippingAddress,
    hasOrdersInArrears,
    queryHasOrders,
    queryOrders,
    searchOrders,
  }
}
